import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import MetaData from '../components/meta/MetaData';
import SignUp from '../components/website/SignUp';
import MediaBlock from '../components/website/MediaBlock';
import yahooFinance from '../images/media/media01.png';
import mobilePayments from '../images/media/media02.png';
import fintechFutures from '../images/media/media03.png';
import apNews from '../images/media/media04.png';
import fintechInShorts from '../images/media/media05.png';
import cioTechie from '../images/media/media06.png';
import fintechInsight from '../images/media/media07.png';
import fintechRanking from '../images/media/media08.png';
import digitalJournal from '../images/media/media09.png';
import itBusinessNet from '../images/media/media10.png';
import finanzNachrichten from '../images/media/media11.png';
import erieNews from '../images/media/media12.png';
import kuamNews from '../images/media/media13.png';
import bloomberg from '../images/media/media14.png';
import illinoisIntel from '../images/media/media15.png';
import { Wrapper, Header, Headline, MediaContainer } from '../styles';

const pics = {
	yahooFinance,
	mobilePayments,
	fintechFutures,
	apNews,
	fintechInShorts,
	cioTechie,
	fintechInsight,
	fintechRanking,
	digitalJournal,
	itBusinessNet,
	finanzNachrichten,
	erieNews,
	kuamNews,
	bloomberg,
	illinoisIntel,
};

const Media = ({ data }) => {
	const media = data.allGoogleMediaSheet.edges.reverse();

	return (
		<Layout>
			<MetaData
				title="Envel - Media"
				description="We’re in the news! View the various news articles to read more about what others have to say about Envel."
				image="images/social/social.png"
			/>

			<Wrapper alignCenter height="auto">
				<div data-sal="slide-up" data-sal-duration="1000" data-sal-delay="100">
					<Header center>Media</Header>
					<Headline center>Click the news articles below to read about Envel in the news</Headline>
				</div>
				<MediaContainer row wrap justifyCenter>
					{media.map(({ node: { picture, text, link } }) => {
						const pic = pics[picture];
						return <MediaBlock key={link} text={text} link={link} picture={pic} />;
					})}
				</MediaContainer>
			</Wrapper>
			<SignUp />
		</Layout>
	);
};

export default Media;

export const mediaQuery = graphql`
	query {
		allGoogleMediaSheet {
			edges {
				node {
					picture
					text
					link
				}
			}
		}
	}
`;
