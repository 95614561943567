import React from 'react';

import { MediaBlock, MediaText, MediaPic, MediaImg, Headline, MediaMore } from '../../styles';

const MediaBlocks = ({ link, text, picture }) => (
	<MediaBlock data-sal="slide-up" data-sal-duration="1000" data-sal-delay="100">
		<MediaPic justifyCenter alignCenter>
			<a href={link} target="_blank" rel="noopener noreferrer">
				<MediaImg src={picture} />
			</a>
		</MediaPic>
		<MediaText>
			<Headline>{text}</Headline>
			<a href={link} target="_blank" rel="noopener noreferrer">
				<MediaMore>read more ...</MediaMore>
			</a>
		</MediaText>
	</MediaBlock>
);

export default MediaBlocks;
